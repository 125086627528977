import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import clickmap1 from 'img/help/clickmaps/clickmap1.png';
import clickmap2 from 'img/help/clickmaps/clickmap2.png';
import clickmap3 from 'img/help/clickmaps/clickmap3.png';
import clickmapfilters from 'img/help/clickmaps/clickmapfilters.png';
import heatmap from 'img/help/clickmaps/heatmap.png';

const Content = () => {
  return (
    <div>
      <SubTitle>Clickmaps - what is it?</SubTitle>
      <p>
        Clickmaps help you to spot your website’s most clickable elements. In short - it shows you
        where your visitors clicked during their visit in a graphical way. In addition, our
        Clickmaps allow you to filter Rage clicks and Error clicks in case you’d like to identify
        your website’s flaws. LiveSession Clickmaps are generated from all the sessions that match
        filters you set on a Sessions’ Dashboard (including the timespan). By default, we use data
        from <strong>all visits</strong>.
      </p>
      <SubTitle>What does the Clickmap look like?</SubTitle>
      <p>
        If you want to access LiveSession Clickmaps, open any session replay you see on the Session
        List and click on a “Clickmap” tab placed over the session player:
      </p>
      <Image src={clickmap1} lazy alt="LiveSession App - Clickmap tab" title="Switch to Clickmap" />
      <p>
        You will get a list of all clicks that were made on the page. They will be listed in the
        panel on the right side of the screen and visible on a session preview:
      </p>
      <Image
        src={clickmap2}
        lazy
        alt="LiveSession App - Clickmap - First view"
        title="Clickmap - example view"
      />
      <p>The list is sorted descending, from the most to the least clickable element.</p>
      <p>
        It is also represented in the session preview. The most clickable elements are assigned with
        lower numbers. You can click on any of the elements in the session preview to see more
        detailed information (which will be visible in the right panel).
      </p>
      <Image
        src={clickmap3}
        lazy
        alt="LiveSession App - Clickmap - click count"
        title="See the number of clicks"
      />
      <p>
        Clickmaps are generated for the site you see in the preview. If you’d like to see clickmaps
        for any other site, go to the part of the recording when the user views the page you are
        interested in. Tip: you can use LiveSession Filters to see all the recordings in which your
        user’s visited any URL.
      </p>
      <p>
        With LiveSession Clickmaps, you can filter Rage and Error Click. Error Clicks are clicks
        that caused JavaScript errors, while Rage Clicks may point to actions that caused your
        users’ frustration. For example, it may indicate a broken link or elements that don’t work
        as they should but don’t throw any JavaScript errors. To filter Error and Rage Clicks, use
        the filter in the right-side panel:
      </p>
      <Image
        src={clickmapfilters}
        lazy
        alt="LiveSession App - Clickmap filters"
        title="Filter type of clicks"
      />
      <p>
        You can also use LiveSession Heatmaps by toggling a switch in session player upper right:
      </p>

      <Image src={heatmap} lazy alt="LiveSesssion App - Heatmap" title="Example of using heatmap" />
      <p>
        LiveSession Heatmaps are a graphic visualization that shows user activities on a website or
        app by adding a semi-transparent overlay to the examined screen using colors, adjusting the
        strength of color to express the number of clicks. They let you spot your website’s hottest
        parts and elements with which your visitors interact the most.
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/what-is-a-session/',
    title: 'What is a session?',
    description: 'A session is a set of the user’s interactions with a website within a timeframe.',
  },
  {
    link: '/help/how-to-record-keystrokes/',
    title: "How to record user's keystrokes?",
    description: 'See how to enable global keystroke tracking.',
  },
];

export const frontmatter = {
  metaTitle: 'How do the Clickmaps work?',
  metaDescription: 'Wondering about the usability of our Clickmaps? Read here.',
  canonical: '/help/how-do-the-clickmaps-work/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
